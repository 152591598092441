<script>
export default {
  data: () => ({
    showModal: false,
    dataSendCashbox: {
      data: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      valor_abertura: 0,
      id_agencia: null,
      id_operador: null,
      status: 0,
    },
  }),
  watch: {
    "$store.state.showCashboxModalOpenCashbox": function (isTrue) {
      if (isTrue) {
        this.openModal();
      } else {
        this.showModal = false;
      }
    },
  },
  methods: {
    openModal() {
      this.$http
        .get(
          `/agencias/valor-nao-transferido/${this.$store.getters.getOpAgency}/${this.$store.getters.getOperador}`
        )
        .then((res) => {
          this.dataSendCashbox.valor_abertura = res.data.valor;
        })
        .finally(() => {
          this.showModal = true;
          this.$store.dispatch("activeLoader", false);
        });
    },
    async openCashbox() {
      this.$store.dispatch("activeLoader", true);

      try {
        this.dataSendCashbox.id_agencia = this.$store.getters.getOpAgency;
        this.dataSendCashbox.id_operador = this.$store.getters.getOperador;

        const response = await this.$http.post(
          `agencias/open-cashbox`,
          this.dataSendCashbox
        );

        this.$store.dispatch("showMessage", {
          show: true,
          color: `${response.data.success ? "success" : "error"} darken-1`,
          text: response.data.success
            ? response.data.success
            : response.data.error,
        });
        this.$store.dispatch("updateCurrentCashboxState", {
          status: false,
          id: response.data.cashbox_id,
        });
        this.showModal = false;
        this.$store.dispatch("showCashboxModalOpenCashbox", false);
        if (!response.data.error) {
          this.$router.replace("/pdv");
        }

        this.$store.dispatch("activeLoader", false);
      } catch (e) {
        this.$store.dispatch("showMessage", {
          show: true,
          color: `error darken-1`,
          text: e,
        });
        this.$store.dispatch("activeLoader", false);
      }
    },
  },
};
</script>
