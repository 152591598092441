<template>
  <v-dialog
    v-model="$store.getters.getShowMessagecashboxLastOpened"
    persistent
    max-width="330"
  >
    <v-card>
      <v-card-title class="text-h5"> Caixa anterior aberto! </v-card-title>
      <v-card-text class="title">
        O caixa do dia {{ date }} está aberto feche primeiro para abrir um novo!
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          block
          dark
          depressed
          @click="() => $store.dispatch('showMessagecashboxLastOpened', false)"
        >
          OK ENTENDI!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["date"],
};
</script>
