<template>
  <v-container class="size mt-9">
    <v-subheader class="title mb-4 text-uppercase pa-0">
      <v-icon class="mr-3" @click="$router.back()">mdi-arrow-left</v-icon>
      {{ "Movimento do Caixa" }}
      <div class="flex-grow-1"></div>
      <v-btn
        color="orange darken-2"
        dark
        depressed
        @click="() => $store.dispatch('showCashboxModalReopenCashbox', true)"
        v-if="totais.fechado && id_caixa ? true : false"
      >
        REABRIR CAIXA
      </v-btn>
    </v-subheader>
    <v-card class="mb-10" height="150">
      <v-toolbar color="primary" dark elevation="0" dense>
        <v-toolbar-title class>SELECIONE A DATA</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-btn v-if="!id_caixa" @click="checkLastCashboxIsOpened" text
          >ABRIR CAIXA</v-btn
        >
        <v-btn @click="printTermal" text>IMPRIMIR TERMICA</v-btn>
        <v-btn @click="printA4" text>IMPRIMIR A4</v-btn>
      </v-toolbar>
      <v-container>
        <v-row dense class="text-center">
          <v-col cols="12" sm="3">
            <Calendar
              :label="'Data'"
              @dateValue="[(search.dataIni = $event), loadValues()]"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="filter"
              :items="['AGÊNCIA', 'OPERADOR LOGADO']"
              label="Filtrar Por:"
              @change="loadValues()"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-row dense>
      <v-col cols="12" md="6">
        <v-card>
          <v-toolbar color="primary" dark elevation="0" dense>
            <v-toolbar-title class>
              <div class="flex-grow-1"></div>
              ENTRADAS
            </v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-tooltip
              bottom
              v-if="search.dataIni === compareDate && !totais.fechado"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  :disabled="totais.fechado ? true : false"
                  dark
                  v-on="on"
                  @click="[(dialog = true), (dados.tipo = 1)]"
                  >mdi-plus</v-icon
                >
              </template>
              <span>NOVA ENTRADA</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="headersInputs"
              :items="itemsInputs"
              :items-per-page="5"
              class="elevation-0"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  v-if="!totais.fechado"
                  small
                  @click="deleteItem(item.id)"
                  color="error"
                  >mdi-delete</v-icon
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-toolbar color="primary" dark elevation="0" dense>
            <v-toolbar-title class>
              <div class="flex-grow-1"></div>
              SAÍDAS
            </v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-tooltip
              bottom
              v-if="search.dataIni === compareDate && !totais.fechado"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  dark
                  v-on="on"
                  @click="[(dialog = true), (dados.tipo = 0)]"
                  >mdi-plus</v-icon
                >
              </template>
              <span>NOVA SAÍDA</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="headersOuts"
              :items="itemsOuts"
              :items-per-page="5"
              class="elevation-0"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  v-if="!totais.fechado"
                  small
                  @click="deleteItem(item.id)"
                  color="error"
                  >mdi-delete</v-icon
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-toolbar color="primary" dark elevation="0" dense>
        <v-toolbar-title class>
          <span class="headline"
            >NOVA {{ dados.tipo == 0 ? "SAÍDA" : "ENTRADA" }}</span
          >
        </v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          autocomplete="off"
          @submit.prevent
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Descrição"
                    v-model="dados.descricao"
                    :rules="fieldRules"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Valor"
                    v-model="dados.valor"
                    :rules="fieldRules"
                    outlined
                    v-mask-decimal.br="2"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-footer absolute>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text dark @click="dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                dark
                @click="[(dialog = false), validate()]"
                :disabled="!valid"
                >Confirmar</v-btn
              >
            </v-footer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-card class="mt-10">
      <v-toolbar color="primary" dark elevation="0" dense>
        <v-toolbar-title class="d-flex justify-space-between flex-grow-1">
          <span class="headline">
            {{ encomendas && encomendas.length }}
            {{
              encomendas && encomendas.length > 1
                ? "- ENCOMENDAS "
                : "- ENCOMENDA"
            }}
          </span>
          <div v-for="(item, i) in resumo.encomenda" :key="i">
            <span>{{ item.formapagamento }}: {{ item.valor }}</span>
          </div>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headersEncomendas"
          :items="encomendas"
          :items-per-page="5"
          class="elevation-0"
        >
          <template v-slot:item.status_pagamento="{ item }">
            {{ item.status_pagamento == 0 ? "À PAGAR" : "PAGO" }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              text
              color="primary"
              :to="`/pdv/financeiro/encomenda/${item.id}`"
              >DETALHES</v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card class="mt-10">
      <v-toolbar color="primary" dark elevation="0" dense>
        <v-toolbar-title class="d-flex justify-space-between flex-grow-1">
          <span class="headline">
            {{ items && items.length }}
            {{
              items && items.length > 1
                ? "- PASSAGENS VENDIDAS"
                : "- PASSAGEM VENDIDA"
            }}
          </span>
          <div v-for="(item, i) in resumo.passagem" :key="i">
            <span>{{ item.formapagamento }}: {{ item.valor }}</span>
          </div>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="5"
          class="elevation-0"
        >
          <template v-slot:item.remarcacao="{ item }">
            <span>{{ item.remarcacao == "1" ? "REMARCAÇÃO" : "VENDA" }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              text
              color="primary"
              :to="`/pdv/financeiro/bilhete/${item.codigo}`"
              >DETALHES</v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card class="mt-10">
      <v-toolbar color="primary" dark elevation="0" dense>
        <v-toolbar-title class="d-flex justify-space-between flex-grow-1">
          <span class="headline">
            {{ excessoBagagem && excessoBagagem.length }} - EXCESSO DE BAGAGEM
          </span>
          <div v-for="(item, i) in resumo.excesso" :key="i">
            <span>{{ item.formapagamento }}: {{ item.valor }}</span>
          </div>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headersExBag"
          :items="excessoBagagem"
          :items-per-page="5"
          class="elevation-0"
        >
        </v-data-table>
      </v-card-text>
    </v-card>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <v-footer fixed padless>
      <v-row class="text-center">
        <v-col cols="12" sm="10" class="d-flex justify-space-between">
          <v-card flat color="transparent text-center">
            <v-card-title class="font-weight-light text-md-body-1"
              >ENT | {{ totais.entradas }}</v-card-title
            >
          </v-card>
          <v-card flat color="transparent text-center">
            <v-card-title class="font-weight-light text-md-body-1"
              >SAÍDAS | {{ totais.saidas }}</v-card-title
            >
          </v-card>
          <v-card flat color="transparent text-center">
            <v-card-title class="font-weight-light text-md-body-1"
              >VENDAS | {{ totais.vendas }}</v-card-title
            >
          </v-card>
          <v-card flat color="transparent text-center">
            <v-card-title class="font-weight-light text-md-body-1"
              >EXC | {{ totais.exc }}</v-card-title
            >
          </v-card>
          <v-card flat color="transparent text-center">
            <v-card-title class="font-weight-light text-md-body-1"
              >ENC | {{ totais.total_encomendas }}</v-card-title
            >
          </v-card>
          <v-card flat color="transparent text-center">
            <v-card-title class="font-weight-light text-md-body-1"
              >ABERTURA | {{ totais.abertura }}</v-card-title
            >
          </v-card>
          <v-card flat color="transparent text-center">
            <v-card-title class="font-weight-light text-md-body-1"
              >TOTAL |
              {{
                (
                  parseFloat(
                    totais.abertura.replace(".", "").replace(",", ".")
                  ) +
                  parseFloat(
                    totais.fechamento.replace(".", "").replace(",", ".")
                  )
                )
                  .toFixed(2)
                  .toString()
                  .replace(".", ",")
              }}</v-card-title
            >
          </v-card>
        </v-col>
        <v-col cols="12" sm="2" class="mt-3">
          <v-btn
            :color="totais.fechado ? 'red' : 'green'"
            :text="totais.fechado"
            dark
            depressed
            @click="isShow = true"
            :disabled="totais.fechado"
          >
            <span :style="totais.fechado ? 'color:red' : 'color:white'">
              {{ totais.fechado ? "CAIXA FECHADO" : "FECHAR CAIXA" }}</span
            >
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
    <ReportSales
      style="display: none"
      :dados="items"
      :data="search.dataIni"
      :saidas="itemsOuts"
      :entradas="itemsInputs"
      :totais="totais"
    />
    <v-dialog v-model="isShow" width="500" persistent>
      <v-card>
        <v-card-title class="headline blue lighten-2">
          <span style="color: #fff"> FECHAMENTO DO CAIXA </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  label="Forma de Envio"
                  :items="formas_envios"
                  v-model="dados_fechamento.forma_envio"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="Valor da Transferência"
                  v-mask-decimal.br="2"
                  v-model="dados_fechamento.valor_transferencia"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div>
            Abertura: {{ totais.abertura }}
            <br />
            Caixa Atual: {{ totais.fechamento }}
            <br />
            <b>
              Total:
              {{
                (
                  parseFloat(
                    totais.abertura.replace(".", "").replace(",", ".")
                  ) +
                  parseFloat(
                    totais.fechamento.replace(".", "").replace(",", ".")
                  )
                )
                  .toFixed(2)
                  .toString()
                  .replace(".", ",")
              }}
            </b>
          </div>
          <v-spacer />
          <v-btn color="red darken-3" text @click="[(isShow = false)]">
            CANCELAR
          </v-btn>
          |
          <v-btn color="green darken-3" text @click="fechamentoCaixa">
            FECHAR CAIXA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <MessageCashboxLastDayOpen :date="messageData.date" />
    <CheckCashboxOpen />
    <v-dialog
      v-model="$store.getters.getShowCashboxModalReopenCashbox"
      absolute
      persistent
      width="350"
    >
      <v-card>
        <v-card-title>REABRIR CAIXA</v-card-title>
        <v-card-text>
          <h3>
            É preciso permissão de administrador, para realizar essa ação.
          </h3>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                label="Login do Administrador"
                v-model="dataSendReopen.login"
              />
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                type="password"
                label="Senha"
                v-model="dataSendReopen.password"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn block color="primary" dark depressed @click="reopenCashbox"
                >REABRIR CAIXA</v-btn
              >
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                block
                color="red"
                dark
                depressed
                @click="$store.dispatch('showCashboxModalReopenCashbox', false)"
                >CANCELAR</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Calendar from "@/components/Calendars.vue";
import ReportSales from "@/components/printers/reports/SalesReportThermal.vue";
import MessageCashboxLastDayOpen from "./components/MessageCashboxLastDayOpen";
import CheckCashboxOpen from "./components/CheckCashboxOpen";
export default {
  components: {
    Calendar,
    ReportSales,
    MessageCashboxLastDayOpen,
    CheckCashboxOpen,
  },
  data: () => ({
    dialog: false,
    isShow: false,
    expanded: [],
    compareDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    headers: [
      { text: "Código", value: "cod_barra", sortable: false },
      { text: "Data/Hora Viagem", value: "data_hora_viagem", sortable: false },
      { text: "Origem", value: "origem", sortable: false },
      { text: "Destino", value: "destino", sortable: false },
      { text: "Valor", value: "valor_apagar", sortable: false },
      { text: "Forma de Pagamento", value: "formapagamento", sortable: false },
      { text: "Data/Hora Venda", value: "datahora", sortable: false },
      { text: "Tipo", value: "remarcacao", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
    headersEncomendas: [
      { text: "Código", value: "cod_barras", sortable: false },
      {
        text: "Data/Recebimento",
        value: "data_retirada",
        sortable: false,
      },
      { text: "Remetente", value: "remetente", sortable: false },
      { text: "Destinatário", value: "destinatario", sortable: false },
      { text: "Tipo", value: "status_pagamento", sortable: false },
      { text: "Natureza", value: "natureza", sortable: false },
      { text: "Valor Total", value: "valor_total", sortable: false },
      { text: "Forma de Pagamento", value: "formapagamento", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
    headersInputs: [
      { text: "Descrição", value: "descricao", sortable: false },
      { text: "Valor R$", value: "valor", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
    headersOuts: [
      { text: "Descrição", value: "descricao", sortable: false },
      { text: "Valor R$", value: "valor", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
    headersExBag: [
      { text: "Código", value: "cod_barra", sortable: false },
      { text: "Quantidade", value: "quantidade", sortable: false },
      { text: "Valor", value: "valor", sortable: false },
      { text: "Forma de Pagamento", value: "formapagamento", sortable: false },
      { text: "Data/Hora Venda", value: "dh_reg_evento", sortable: false },
    ],
    search: {
      dataIni: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    },
    items: [],
    itemsInputs: [],
    itemsOuts: [],
    encomendas: [],
    excessoBagagem: [],
    formas_envios: ["MALOTE", "DEPÓSITO"],
    filter: "OPERADOR LOGADO",
    dados_fechamento: {
      valor_transferencia: 0,
      forma_envio: "MALOTE",
      id_agencia: null,
      cashbox_id: null,
    },
    dados: {
      descricao: "",
      valor: 0.0,
      tipo: 0,
      id_operador: null,
      id_agencia: null,
    },
    totais: {
      abertura: 0,
      entradas: 0,
      saidas: 0,
      vendas: 0,
      fechamento: 0,
      total_encomendas: 0,
      fechado: false,
      encomendas_realizadas: 0,
      passagens_vendidas: 0,
      exc: 0,
    },
    id_caixa: null,
    valid: true,
    fieldRules: [(v) => !!v || "Este campo é requerido"],
    messageData: {
      opened: false,
      date: "",
    },
    dataSendReopen: {
      login: "",
      password: "",
      cashbox_id: null,
    },
    resumo: {
      passagem: [],
      encomenda: [],
      excesso: [],
    },
  }),
  created() {
    this.loadValues();
    this.checkCashboxOpen(true);
  },
  methods: {
    async reopenCashbox() {
      this.$store.dispatch("activeLoader", true);

      try {
        this.dataSendReopen.cashbox_id = this.id_caixa;
        const response = await this.$http.post(
          `/caixa/reopen`,
          this.dataSendReopen
        );

        this.$store.dispatch("showMessage", {
          show: true,
          color: `${response.data.success ? "success" : "error"} darken-1`,
          text: response.data.success
            ? response.data.success
            : response.data.error,
        });

        this.$store.dispatch("showCashboxModalReopenCashbox", false);
        this.loadValues();
        this.$store.dispatch("activeLoader", false);
      } catch (e) {
        this.$store.dispatch("showMessage", {
          show: true,
          color: `error darken-1`,
          text: e,
        });
        this.$store.dispatch("activeLoader", false);
      }
    },
    async checkLastCashboxIsOpened() {
      const { data } = await this.$http.post(`/caixa/cashbox-lastday-opened`, {
        agency_id: this.$store.getters.getOpAgency,
        operator_id: this.$store.getters.getOperador,
      });
      this.messageData = data;
      if (data.opened) {
        this.$store.dispatch("showMessagecashboxLastOpened", true);
      } else {
        this.checkCashboxOpen();
        this.$store.dispatch("showMessagecashboxLastOpened", false);
      }
    },
    checkCashboxOpen(isStarting) {
      this.$store.dispatch("showCashboxModalOpenCashbox", false);
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`agencias/check-cashbox-open`, {
          agency_id: this.$store.getters.getOpAgency,
          operator_id: this.$store.getters.getOperador,
        })
        .then(({ data }) => {
          this.$store.dispatch("updateCurrentCashboxState", data);
          if (!data.status) {
            if (this.$store.getters.getOperadorLevel >= 5) {
              if (!isStarting) {
                this.$store.dispatch("showCashboxModalOpenCashbox", true);
              } else {
                this.$store.dispatch("activeLoader", false);
              }
            }
          }
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    fechamentoCaixa() {
      const valorTransferencia = this.dados_fechamento.valor_transferencia
        ? this.dados_fechamento.valor_transferencia
        : "0,00";
      const valorFechamento = this.totais.fechamento
        ? this.totais.fechamento
        : "0,00";
      const valorAbertura = this.totais.abertura
        ? this.totais.abertura
        : "0,00";
      if (!valorTransferencia) {
        this.dados_fechamento.valor_transferencia = "0,00";
      }

      const valorFinal =
        parseFloat(valorAbertura.replace(",", "").replace(".", "")) +
        parseFloat(valorFechamento.replace(",", "").replace(".", ""));

      if (
        parseFloat(valorTransferencia.replace(",", "").replace(".", "")) >
        valorFinal
      ) {
        this.$store.dispatch("showMessage", {
          show: true,
          color: "warning",
          text: "Impossível completar operação! Valor informado maior do que o valor do caixa atual!",
        });
        this.isShow = true;
        return;
      }

      const valorRestante =
        parseFloat(valorAbertura.replace(".", "").replace(",", ".")) +
        parseFloat(valorFechamento.replace(".", "").replace(",", ".")) -
        parseFloat(valorTransferencia.replace(".", "").replace(",", "."));

      this.$http
        .post("/livrocaixa/fechamento-agencia", {
          id_agencia: this.$store.getters.getOpAgency,
          id_operador: this.$store.getters.getOperador,
          forma_envio: this.dados_fechamento.forma_envio,
          valor_transferencia: this.dados_fechamento.valor_transferencia,
          valor_nao_transferido: valorRestante,
          date: this.search.dataIni,
          id_caixa: this.id_caixa,
        })
        .then((response) => {
          if (response.data.success) {
            this.isShow = false;
            this.$store.dispatch("showMessage", {
              show: true,
              color: "success darken-1",
              text: response.data.success,
            });
            this.dados_fechamento.valor_transferencia = 0;
            this.loadValues();

            this.$store.dispatch("updateCurrentCashboxState", {
              cashbox_id: this.$store.getters.getCurrentCashboxId,
              status: false,
            });

            this.$store.dispatch("activeLoader", false);
          } else {
            this.$store.dispatch("showMessage", {
              show: true,
              color: "error darken-1",
              text: response.data.error,
            });
            this.$store.dispatch("activeLoader", false);
          }
        })
        .catch((error) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: error,
          });
        });
    },
    printA4() {
      this.items = [];
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/caixa/valores`, {
          data: this.search.dataIni,
          operador: this.$store.getters.getOperador,
          agencia: this.$store.getters.getOpAgency,
          filtro: this.filter,
        })
        .then((r) => {
          this.encomendas = r.data[0].encomendas;
          this.itemsInputs = r.data[0].entradas;
          this.itemsOuts = r.data[0].saidas;
          this.items = r.data[0].transacoes;
          this.totais.abertura = r.data[0].abertura;
          this.totais.entradas = r.data[0].total_entradas;
          this.totais.saidas = r.data[0].total_saidas;
          this.totais.vendas = r.data[0].total_vendas;
          this.totais.fechamento = r.data[0].fechamento;
          this.totais.total_encomendas = r.data[0].total_encomendas;
          this.totais.fechado = r.data[0].fechado;
          this.$store.dispatch("activeLoader", false);
          this.id_caixa = r.data[0].cashbox_id;
          this.totais.passagens_vendidas = r.data[0].passagens_vendidas;
          this.totais.encomendas_realizadas = r.data[0].encomendas_realizadas;
          this.$htmlToPaper("imprimir");
        });
    },
    loadValues() {
      this.items = [];
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/caixa/valores`, {
          data: this.search.dataIni,
          operador: this.$store.getters.getOperador,
          agencia: this.$store.getters.getOpAgency,
          filtro: this.filter,
        })
        .then((r) => {
          this.encomendas = r.data[0].encomendas;
          this.itemsInputs = r.data[0].entradas;
          this.itemsOuts = r.data[0].saidas;
          this.items = r.data[0].transacoes;
          this.excessoBagagem = r.data[0].excesso_bagagem;
          this.totais.abertura = r.data[0].abertura;
          this.totais.entradas = r.data[0].total_entradas;
          this.totais.saidas = r.data[0].total_saidas;
          this.totais.vendas = r.data[0].total_vendas;
          this.totais.exc = r.data[0].total_exc;
          this.totais.fechamento = r.data[0].fechamento;
          this.totais.total_encomendas = r.data[0].total_encomendas;
          this.totais.fechado = r.data[0].fechado;
          this.$store.dispatch("activeLoader", false);
          this.id_caixa = r.data[0].cashbox_id ? r.data[0].cashbox_id : false;
          this.totais.passagens_vendidas = r.data[0].passagens_vendidas;
          this.totais.encomendas_realizadas = r.data[0].encomendas_realizadas;
          this.resumo.passagem = r.data[0].resumo_passagens.formasdepagamento;
          this.resumo.encomenda = r.data[0].resumo_encomendas.formasdepagamento;
          this.resumo.excesso =
            r.data[0].resumo_excesso_de_bagagem.formasdepagamento;
        });
    },
    printTermal() {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/caixa/valores/impressao`, {
          data: this.search.dataIni,
          operador: this.$store.getters.getOperador,
          agencia: this.$store.getters.getOpAgency,
          filtro: this.filter,
        })
        .then(() => {
          this.$store.dispatch("activeLoader", false);
        });
    },
    salvar() {
      this.dados.id_operador = this.$store.getters.getOperador;
      this.dados.id_agencia = this.$store.getters.getOpAgency;
      this.$http.post(`/caixa/operacao/add`, this.dados).then((r) => {
        this.loadValues();
        this.dados.descricao = "";
        this.dados.tipo = "";
        this.dados.valor = "";
        this.dados.id_operador = "";
        this.dados.id_agencia = 0;
        this.message.snacshow = true;
        this.message.type = "success";
        this.message.text = r.data.result;
        this.$store.dispatch("activeLoader", false);
      });
    },
    deleteItem(id) {
      let c = confirm("Deseja apagar o registro?");
      if (c) {
        this.$store.dispatch("activeLoader", true);
        this.$http.post(`/caixa/operacao/delete/${id}`).then((r) => {
          this.$store.dispatch("activeLoader", false);
          alert(r.data.success);
          this.loadValues();
        });
      }
    },
  },
};
</script>
